





























































































































































































































































































































































































































































import { computed, defineComponent, PropType, ref, onUnmounted } from '@vue/composition-api';
import { useProduct, ProductProperty } from '@/admin/product';
import { useNotification } from '@/composition/notification';
import { useConfirm } from '@/composition/confirm';
import FcDate from '@/components/FcDate.vue';
import FcImage from '@/components/FcImage.vue';
import FcImages from '@/components/FcImages.vue';
import FcStaticImage from '@/components/FcStaticImage.vue';
import FcStaticImages from '@/components/FcStaticImages.vue';
import FcAuthority from '@/components/FcAuthority.vue';
import FcNumberForm from '@/components/FcNumberForm.vue';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';
import { usePurchasePreview } from '@/views/purchase/composition/purchasePreview';

export default defineComponent({
  name: 'Product',
  components: {
    FcDate,
    FcImage,
    FcImages,
    FcStaticImage,
    FcStaticImages,
    FcAuthority,
    FcNumberForm,
    FcRoleLoading,
    FcRoleDeny,
  },
  props: {
    productId: {
      type: String,
      require: false,
      default: '',
    },
    copyProduct: {
      type: Object as PropType<ProductProperty | null>,
      require: false,
      default: null,
    },
  },
  setup({ productId, copyProduct }, context) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('products'));
    const isShippingAddress: boolean = process.env.VUE_APP_IS_SHIPPING === 'true';

    const { product } = useProduct(productId);
    const purchasePreview = usePurchasePreview(productId);
    const isSaving = ref(false);

    if (copyProduct) {
      product.setCopyProduct(copyProduct);
    }
    const notification = useNotification();
    const { confirmDialog } = useConfirm();
    const expirationTypeItems = [
      {
        value: 'date',
        text: '日付指定',
      },
      {
        value: 'period',
        text: '期間指定',
      },
    ];
    const expirationPeriodIntervalItems = [
      {
        value: 'day',
        text: '日',
      },
      {
        value: 'week',
        text: '週',
      },
      {
        value: 'month',
        text: '月',
      },
      {
        value: 'year',
        text: '年',
      },
    ];
    const deliveryMonthsOptions = [
      {
        value: 0,
        text: '即時発行のデジタルコンテンツ',
      },
      {
        value: 1,
        text: '1ヶ月',
      },
      {
        value: 2,
        text: '2ヶ月',
      },
      {
        value: 3,
        text: '3ヶ月',
      },
      {
        value: 4,
        text: '4ヶ月',
      },
      {
        value: 5,
        text: '5ヶ月',
      },
      {
        value: 6,
        text: '6ヶ月',
      },
    ];

    const save = async () => {
      if (!(await confirmDialog('本当に保存しますか？'))) return;
      isSaving.value = true;
      try {
        if (productId) {
          await product.saveProduct(productId);
          notification.notify('変更しました。');
        } else {
          await product.createProduct();
          notification.notify('作成しました。');
          context.root.$router.push('/products');
        }
      } catch (error) {
        notification.error(error);
      }
      isSaving.value = false;
    };

    /**
     * プレビュー用の値をストレージに保存する
     */
    const saveStorage = () => {
      try {
        purchasePreview.saveStorage(product);
      } catch (e) {
        useNotification().error(e);
        event?.preventDefault();
      }
    };

    onUnmounted(() => {
      // ページを離れた時に、ストレージをリセットする
      purchasePreview.removeStorage(productId);
    });

    return {
      pageTitle: '個別課金',
      myRoleSettings,
      isShippingAddress,
      product,
      save,
      expirationTypeItems,
      expirationPeriodIntervalItems,
      deliveryMonthsOptions,
      isSaving,
      saveStorage,
    };
  },
});
