import { reactive } from '@vue/composition-api';
import { Product, MaxProductImagesCount } from '@/admin/product';
import { DisplayDate } from '@/admin/util';

class PurchasePreview {
  storageKey: string; // ローカルストレージキーを保持する

  constructor(productId?: string) {
    if (!productId) {
      this.storageKey = 'productId:newCreate';
    } else {
      this.storageKey = `productId:${productId}`;
    }
  }

  /**
   * 有効期限を計算し、取得する
   *
   * @returns 有効期限
   */
  calculationInterval = (product: Product, expirationDate: Date) => {
    if (product.expirationProperty.expirationPeriod.interval === 'day') {
      expirationDate.setDate(new Date().getDate() + product.expirationProperty.expirationPeriod.intervalCount);
      return expirationDate;
    } else if (product.expirationProperty.expirationPeriod.interval === 'week') {
      expirationDate.setDate(new Date().getDate() + 7 * product.expirationProperty.expirationPeriod.intervalCount);
      return expirationDate;
    } else if (product.expirationProperty.expirationPeriod.interval === 'month') {
      expirationDate.setMonth(new Date().getMonth() + product.expirationProperty.expirationPeriod.intervalCount);
      return expirationDate;
    } else {
      expirationDate.setFullYear(new Date().getFullYear() + product.expirationProperty.expirationPeriod.intervalCount);
      return expirationDate;
    }
  };

  /**
   * ストレージにプレビュー用の値を保持する
   *
   * @param productId - プレビューする商品ID
   */
  saveStorage = (product: Product) => {
    if (
      !product.item.productName ||
      !product.item.thumbnail ||
      !product.item.price.toString().match(/^\d+$/) ||
      product.item.purchaseStartDate.value === undefined ||
      product.item.accessStartDate.value === undefined
    )
      throw '商品名，料金，サムネイル, 開始日で未入力があります';
    else if (product.item.images && product.item.images.length > MaxProductImagesCount) {
      throw '19枚を超える追加画像は設定出来ません';
    } else if (0 < product.item.price && product.item.price < 50) throw '50円未満の商品は作成できません';
    else if (product.isPutButton && (!product.buttonProperty.linkText || !product.buttonProperty.linkUrl)) {
      throw 'ボタンを配置する場合はURLとボタンテキストが必須です';
    } else if (
      product.isPutButton &&
      !product.buttonProperty.linkUrl.match(/^(https?|ftp)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)$/)
    ) {
      throw 'リンクが不正です。ドメインから指定してください';
    } else if (
      !product.item.purchaseAuthority.acceptAllMembers &&
      !product.item.purchaseAuthority.planIds?.length &&
      !product.item.purchaseAuthority.seasonIds?.length
    )
      throw '購入権限を指定してください';
    else if (
      (product.isExpiration &&
        product.expirationProperty.expirationType === 'date' &&
        product.expirationProperty.expirationDate.value === undefined) ||
      (product.isAccessEnd && product.item.accessEndDate.value === undefined) ||
      (product.isPurchaseEnd && product.item.purchaseEndDate.value === undefined)
    ) {
      throw '不正な日時です';
    } else if (
      product.isExpiration &&
      product.expirationProperty.expirationType === 'date' &&
      product.expirationProperty.expirationDate.value &&
      (product.expirationProperty.expirationDate.value <= product.item.purchaseStartDate.value ||
        (product.isPurchaseEnd &&
          product.item.purchaseEndDate.value &&
          product.expirationProperty.expirationDate.value <= product.item.purchaseEndDate.value))
    ) {
      throw '購入可能期間は有効期限内にしてください';
    } else if (
      (!product.isPurchaseEnd &&
        !product.isAccessEnd &&
        product.item.accessStartDate.value > product.item.purchaseStartDate.value) ||
      (product.isPurchaseEnd &&
        product.isAccessEnd &&
        product.item.accessEndDate.value &&
        product.item.purchaseEndDate.value &&
        product.item.accessStartDate.value > product.item.purchaseStartDate.value &&
        product.item.accessEndDate.value < product.item.purchaseEndDate.value)
    ) {
      throw '購入可能期間は閲覧期限以内にしてください';
    } else if (!product.isPurchaseEnd && product.isAccessEnd) {
      // 購入終了日がなくて閲覧終了日がある場合は、閲覧できないけど購入できる期間が発生するのでエラーとする
      throw '購入終了日は閲覧終了日以前で設定してください';
    } else if (product.isMaxSalesQuantityPerUser && !product.item.maxSalesQuantityPerUser) {
      throw '一人当たりの最大購入可能数を確認してください';
    } else if (product.item.maxSalesQuantityPerUser && product.item.maxSalesQuantityPerUser > 100) {
      throw '一人当たりの最大購入可能数は100より大きい値を設定できません。制限をかけたくない場合は入力を行わずに保存を行なってください';
    } else if (product.item.deliveryMonths === undefined) {
      throw 'お届けまでの目安を設定してください';
    } else if (
      product.isStockSettings &&
      product.item.stockSettings &&
      (!product.item.stockSettings.maxSalesQuantity ||
        product.item.stockSettings.thresholdOfRemainingQuantity > product.item.stockSettings.maxSalesQuantity)
    ) {
      throw '在庫設定を確認してください';
    } else {
      // ボタンを配置しない場合は、ボタンのプロパティの値を空にする
      const buttonProperty = product.isPutButton
        ? product.buttonProperty
        : { linkText: '', linkUrl: '', isExternalLink: false };
      // ボタンON/OFFの考慮を行なったproductオブジェクトを作成する
      const productObject = {
        ...product,
        buttonProperty: buttonProperty,
      };
      // 作成したproductオブジェクトの値をストレージに保存する
      window.localStorage.setItem(
        this.storageKey,
        JSON.stringify({
          createDate: DisplayDate.now().dateTime,
          ...productObject,
        })
      );
    }
  };

  /**
   * ストレージに保持したプレビュー用の値を取り除く
   *
   * @param productId - プレビューする商品ID
   */
  removeStorage = (productId?: string) => {
    if (productId && window.localStorage.getItem(`productId:${productId}`)) {
      window.localStorage.removeItem(`productId:${productId}`);
    } else if (!productId && window.localStorage.getItem('productId:newCreate')) {
      window.localStorage.removeItem('productId:newCreate');
    }
  };

  /**
   * ストレージに保持したプレビュー用の値を取得する
   *
   * @param productId - プレビューする商品ID
   * @returns プレビュー用の値
   */
  getStorageValue = (productId?: string) => {
    const jsonString = productId
      ? window.localStorage.getItem(`productId:${productId}`)
      : window.localStorage.getItem('productId:newCreate');
    if (jsonString) return JSON.parse(jsonString);
    return undefined;
  };
}

export const usePurchasePreview = (productId?: string) => {
  const purchasePreview = new PurchasePreview(productId);
  return reactive(purchasePreview);
};
