























import { Ref, defineComponent, ref, watch, PropType } from '@vue/composition-api';
import { download, upload, resizeImage } from '@/admin/file';
import { useNotification } from '@/composition/notification';

const MaxImageCount = 19;

export interface HTMLElementEvent<T extends HTMLElement> extends Event {
  target: T;
}
export default defineComponent({
  props: {
    value: {
      type: Array as PropType<string[]>,
      default: () => [],
      required: true,
    },
    isNotResize: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const notification = useNotification();
    const src: Ref<string[]> = ref([]);
    const isUploading = ref(false);
    const fileInput = ref();
    const selectedDeleteIndexes: Ref<number[]> = ref([]);

    const removeImage = () => {
      // props.valueから選んだindexの要素を削除
      const _propsValue = props.value.filter((_, index) => !selectedDeleteIndexes.value.includes(index));
      // props.valueを更新
      context.emit('input', _propsValue);
      selectedDeleteIndexes.value = [];
    };

    const onFocus = () => fileInput.value.click();
    const onFileChange = async (e: HTMLElementEvent<HTMLInputElement>) => {
      const files = e.target.files || ([] as File[]);
      if (files.length === 0) return;
      if (files.length + props.value.length > MaxImageCount) {
        notification.error('19枚を越える追加画像は設定できません');
        return;
      }
      for (const file of files) {
        if (!['image/png', 'image/jpeg', 'image/gif', 'image/svg+xml'].includes(file.type)) {
          notification.error('画像（png, jpeg, jpg, gif, svg）しかアップロードできません');
          return;
        }
      }
      isUploading.value = true;
      try {
        const _filePaths = [];
        for (const file of files) {
          const filePath = await upload(file);
          if (!props.isNotResize && ['image/png', 'image/jpeg'].includes(file.type)) await resizeImage(filePath);
          _filePaths.push(filePath);
        }
        context.emit('input', props.value.concat(_filePaths));
      } catch (e) {
        notification.error('画像のアップロードに失敗しました');
      } finally {
        isUploading.value = false;
        e.target.value = '';
      }
    };

    const downloadImagePaths = async (value: string[]) => {
      const _src = [];
      for (const filePath of value) {
        const imagePath = await download(filePath);
        _src.push(imagePath);
      }
      src.value = _src;
    };
    downloadImagePaths(props.value);
    watch(
      () => props.value,
      async () => {
        await downloadImagePaths(props.value);
      }
    );

    return {
      removeImage,
      onFocus,
      onFileChange,
      MaxImageCount,
      fileInput,
      src,
      isUploading,
      selectedDeleteIndexes,
    };
  },
});
