








import { Ref, PropType } from '@vue/composition-api';
import { defineComponent, ref, watch } from '@vue/composition-api';
import { download } from '@/admin/file';

export default defineComponent({
  props: {
    images: {
      type: Array as PropType<string[]>,
      default: () => [],
      required: true,
    },
  },
  setup(props) {
    const src: Ref<string[]> = ref([]);
    const downloading = ref(true);

    const downloadImagePaths = async (value: string[]) => {
      downloading.value = true;
      const _src = [];
      for (const filePath of value) {
        const imagePath = await download(filePath);
        _src.push(imagePath);
      }
      src.value = _src;
      downloading.value = false;
    };
    downloadImagePaths(props.images);
    watch(
      () => props.images,
      () => {
        downloadImagePaths(props.images);
      }
    );

    return { src, downloading };
  },
});
